import { createReducer, on } from '@ngrx/store';

import { ICategoriesState } from './categories-state.interface';
import * as CategoriesActions from './categories.actions';

export const categoriesFK = 'categoriesFK';

export const initialState: ICategoriesState = {
  categories: [],
  categoriesWithArticles: [],
  error: {
    all: null,
  },
  isLoading: {
    all: false,
  },
};

export const categoriesReducer = createReducer(
  initialState,

  on(
    CategoriesActions.getAllCategories,
    (state): ICategoriesState => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        all: true,
      },
    })
  ),

  on(
    CategoriesActions.getAllCategoriesSuccess,
    (state, action): ICategoriesState => {
      return {
        ...state,
        categories: action.categories,
        categoriesWithArticles: action.categoriesWithArticles,
        isLoading: {
          ...state.isLoading,
          all: false,
        },
      };
    }
  ),

  on(
    CategoriesActions.getAllCategoriesFailure,
    (state, action): ICategoriesState => ({
      ...state,
      error: {
        ...state.error,
        all: action.error,
      },
      isLoading: {
        ...state.isLoading,
        all: false,
      },
    })
  )
);
