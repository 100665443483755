import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICategoriesState } from './categories-state.interface';
import { categoriesFK } from './categories.reducer';

const selectFeature = createFeatureSelector<ICategoriesState>(categoriesFK);

export const selectIsLoading = createSelector(
  selectFeature,
  (state: ICategoriesState) => state.isLoading.all
);

export const selectAllCategories = createSelector(
  selectFeature,
  (state: ICategoriesState) => state.categories
);

export const selectCategoriesWithArticles = createSelector(
  selectFeature,
  (state: ICategoriesState) => {
    return state.categoriesWithArticles;
  }
);
